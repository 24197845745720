<template src='./bank.html'></template>
<style scoped src="./bank.css"> 
</style>

<script>
import { mapActions ,mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import { required,email } from 'vuelidate/lib/validators'
import appConstants from '@/constants/AppConstants'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
import BankService from '../../services/BankService.vue'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    data(){
        return {
            bankdetails: {},
            useremail :'',
            bankItems  : [],
            option : {
                headings : {
                    Id                : 'Bank Account Id',
                    AccountNumber     : 'Account Number',
                    InstitutionNumber : 'Institution Number',
                    BankAccountStatus : 'Account Status',
                    LastUpdated       : 'Last Updated',
                    TransitNumber     : 'Transit Number'
                }
            },
            columns : ['Id','AccountNumber','InstitutionName', 'InstitutionNumber','TransitNumber','Balance', 'BankAccountStatus','LastUpdated','Action'],          
            showBankDetails : false,
            loaderVisible : false,
            errorMessage : appConstants.formsMessages,
            Utils: Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: appConstants.enableLazyLoading
        }
    },
    methods: {
        ...mapActions(['fetchBankDetails', 'setEmailId']),
        async onShowBankInfo () {
            try {
                this.$v.$touch()
                if(this.$v.$invalid){
                    return
                }
                this.loaderVisible = true 
                await this.fetchBankDetails(this.useremail)
                this.bankItems = this.getBankDetails
                this.bankItems = this.bankItems ? (Array.isArray(this.bankItems) ? this.bankItems : [this.bankItems]) : []
                if (this.isLazyLoadingEnabled) {
                    this.$refs.paginationRef.selectedPage = 1
                }
                this.loaderVisible = false            
                this.setEmailId(this.useremail)   
            } catch (error) {
                this.loaderVisible = false
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)
            }
        },
        onShowAccountDetails (id) {  
            this.showBankDetails = true
            this.bankdetails = this.getBankDetails.find(s=> s.Id === id)
        },
        RefreshBankAccount () {
            this.$bvModal.msgBoxConfirm('Are you sure you want to refresh the bank account?')
            .then(async value => {
                if (value) {
                    this.loaderVisible = true
                    await BankService.methods.RefreshBankDetails({
                        OperationType: 'U',
                        CustomerEmail: this.useremail,
                        BankAccounts: this.bankdetails
                    })
                    .then(() => {
                        this.showAlert(1, 'Bank Account Refreshed Successfully')
                    }).catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                    this.loaderVisible = false             
                }               
            })
            .catch(err => {  
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)              
            })            
        },
        GoBack(){
            this.showBankDetails = false
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed: {
        ...mapGetters(['getBankDetails', 'getEmailId']),
        bankAccountsTable() {
            let bankAccounts = [], bankAccountsData = []
            if (this.bankItems && this.bankItems.length > 0) {
                bankAccounts = JSON.parse(JSON.stringify(this.bankItems))
                if (this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    bankAccounts = bankAccounts.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                bankAccounts.map((el) => {
                    bankAccountsData.push({
                        'Id': el.Id,
                        'AccountNumber': el.AccountNumber,
                        'InstitutionName': el.Institution,
                        'InstitutionNumber': el.InstitutionNumber,
                        'BankAccountStatus': el.BankAccountStatus,
                        'TransitNumber': el.TransitNumber,
                        'Balance': el.SupplementalAccountData.AccountSummary.CurrentBalance || '',
                        'LastUpdated': el.SupplementalAccountData.AccountSummary.LastUpdated ? Utils.methods.UTCtoLocal(moment(moment(el.SupplementalAccountData.AccountSummary.LastUpdated, 'MM/DD/YYYY hh:mm:ss A')._d).format('MM-DD-YYYY HH:mm:ss')) : null
                    })
                })
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.bankItems?.length ?? 0
                }
            }
            this.isLazyLoadingEnabled ? updateTotalRecords() : null
            return bankAccountsData
        }
    },
    components : {
        Loading, modal, pagination
    },
    validations : {
        useremail : {required,email}
    }
}
</script>